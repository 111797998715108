.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App p {
  font-size: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100vw;
  /*background-color: #282c34;*/
  background-color: #000000;
  min-height: calc(100vh - 175px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-right: 10vw;
  padding-left: 10vw;
}

.App-header p {
  max-width: 1200px;
  padding-top: 30px;
}

.App-link {
  color: #3F51B7;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#header {
  height: 100px;
  width: 100%;
  background: white;
  border-bottom: 1px solid #ccc;
  border-top: 5px solid #3F51B7;
}


#logo {
  font-size: 25px;
  float: left;
  margin-top: 20px;
}

.ms-auto {
  background-color: white;
}

a.nav-link {
  padding-left: 30px !important;
}

#logo img {
  display: block;
  float: left;
  height: 60px;
  margin-top: -15px;
  margin-right: -10px;
  animation: girar infinite 30s linear;
}

@keyframes girar {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.not-showing {
  transition: visibility 0.5s, opacity 0.5s linear;
  opacity: 0;
  visibility: hidden;
  height: 0px;
}
.showing {
  transition: visibility 2s, opacity 0.8s linear;
  opacity: 1;
  visibility: visible;
}

#img-loading{
  max-width: 60vw;
}

#brand {
  display: block;
  margin: 0px;
  padding-left: 20px;
  float: left;
}

#menu{
  float:right;
  font-size: 18px;
  vertical-align:middle;
  margin-top: 30px;
}

#menu ul{
  line-height: 85px;
  float:right;
}

#menu ul li{
  display: inline-block;
  list-style: none;
  height: 46px;
  margin-left: 15px;
  margin-right: 15px;
}

#menu a{
  text-decoration: none;
  color: #444;
  transition: 300ms all;
}

#menu a:hover{
  color: #3F51B7;
}

input#terms {
  margin-right: 10px;
}

#div-error{
  border-color: white;
  border: 1px solid white;
  padding: 20px;
  margin-top: 30px;
  min-width: 200px;
  max-width: 80vw;
}

.clearfix{
  clear:both;
  float:none;
}

.center{
  width: 85%;
  margin: 0px auto;
}

.btn-white{
  display: block;
  background: white;
  color:rgb(88, 88, 88);
  padding: 10px;
  width: 100px;
  height: 35px;
  margin: 0px auto;
  font-size: 18px;
  text-transform: uppercase;
  text-shadow: none;
  text-decoration: none;
  line-height: 35px;
  box-shadow: 0px 0px 5px rgb(88, 88, 88);
  margin-top: 40px;
  border-radius: 4px;
  transition: 300ms all;
}

#radio-buttons {
  padding-bottom: 30px;
}

.RadioButton {
  margin-left: 5px;
  margin-right: 30px;
}

.btn-white:hover{
  background: #444;
  color:white;
}

#footer{
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  line-height: 20px;
  color: #444;
}

#footer p {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
}

hr {
  border: 1px solid white;
}

#formServicios {
  padding-top: 20px;
}

.buttonExport {
  margin:10px;
}

.btn-info{
  background-color: #3F51B7 !important;
  border-color: #3F51B7 !important;
  color: #ffffff !important;
}

footer a {
  color: #3F51B7 !important;
}








@media (max-width: 1000px){

  #header {
    padding-top: 15px;
    width: 100vw;
  }

  #logo{
      float:left;
      width: 280px;
      margin: 0px auto;
  }

  #menu,
  #menu ul{
     clear:both;
     float:right;
     width: 100%;
     margin: 0px;
     padding: 0px;
     line-height: 43px;
  }

  #content{
      float:none;
      width: 100%;
  }

  #slider h1{
      padding-top: 115px;
      font-size: 25px;
  }

  .mid-form{
      width: 100%;
  }
}
